<template>
  <div
    v-if="products.length > 0"
    :id="`product-list-slider-${config.categoryBlockId}`"
    class="product-list-slider"
    :class="classes"
    :style="style"
  >
    <v-container>
      <ebsn-meta
        :target="config"
        :path="`categoryblocktype_${name}.TITLE`"
        tag="h2"
      />
      <ebsn-meta
        :target="config"
        :path="`categoryblocktype_${name}.DESCRIPTION`"
        tag="div"
      />
      <div class="slider product-slider">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide v-if="showCard">
            <product-slider-card
              :img="
                $ebsn.meta(
                  config,
                  'categoryblocktype_' + name + '.CARD_IMAGE.location'
                )
              "
              :text="
                $ebsn.meta(config, 'categoryblocktype_' + name + '.CARD_TEXT')
              "
              :link="{
                name: 'Category',
                params: {
                  pathMatch: $ebsn.meta(
                    this.config,
                    'categoryblocktype_' + name + '.CATEGORY.slug'
                  )
                }
              }"
              :linkLabel="
                $ebsn.meta(config, 'categoryblocktype_' + name + '.BUTTON_TEXT')
              "
            />
          </swiper-slide>
          <swiper-slide
            v-for="(product, index) in products"
            :key="product.productId"
          >
            <ProductCard
              v-bind:product="product"
              @selectProduct="
                $emit('selectProduct', { product: product, index: index })
              "
            />
          </swiper-slide>
        </swiper>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_BULLETS',
              true
            ) && products.length > 1
          "
          :id="`product-list-pagination-${config.categoryBlockId}`"
          :class="
            `swiper-pagination product-list-pagination-${config.categoryBlockId}`
          "
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_ARROWS',
              false
            ) && products.length > 1
          "
          :id="`product-list-slider-prev-${config.categoryBlockId}`"
          class="swiper-button-prev"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_ARROWS',
              false
            ) && products.length > 1
          "
          :id="`product-list-slider-next-${config.categoryBlockId}`"
          class="swiper-button-next"
        ></div>
      </div>
      <div v-if="showMore && !showCard" class="text-right show-more">
        <v-btn
          link
          text
          class="primary--text text-none "
          :to="{
            name: 'Category',
            params: {
              pathMatch: $ebsn.meta(
                this.config,
                'categoryblocktype_' + name + '.CATEGORY.slug'
              )
            }
          }"
        >
          {{ $t("categoryBlock.productListSlider.showAll") }}
          <v-icon>$arrowForward</v-icon>
        </v-btn>
      </div>
    </v-container>
  </div>
</template>
<style global lang="scss">
.product-list-slider {
  h2 {
    text-align: center;
  }
  .product-slider-card {
    width: 260px;
    height: 380px;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .swiper-slide {
    width: auto;
  }
}
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductSliderCard from "./ProductSliderCard.vue";

import categoryBlockType from "@/components/categoryBlock/categoryBlockType";
// import analyticsService from "~/service/analyticsService";

import Vue from "vue";

export default {
  name: "ProductListSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true },
    products: { type: Array, default: () => [] },
    categoryBlockName: { type: String, required: true },
    showMore: { type: Boolean, default: false }
  },
  components: { ProductCard, ProductSliderCard },
  mixins: [categoryBlockType],
  data() {
    return {
      name: this.categoryBlockName,
      swiperDefaultOption: {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `.product-list-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#product-list-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  computed: {
    showCard() {
      return Vue.$ebsn.meta(
        this.config,
        `categoryblocktype_${this.name}.SHOW_CARD`,
        false
      );
    },
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    },
    classes() {
      return [
        "product-list-slider",
        Vue.$ebsn.meta(
          this.config,
          `metaData.categoryblocktype_${this.name}.CARD_MODE`,
          "vertical"
        ),
        this.showCard ? "has-card" : ""
      ];
    }
  }
  // ,
  // methods: {
  //   handleView(entries, observer, isIntersecting) {
  //     if (isIntersecting && this.proposal?.length > 0) {
  //       // analyticsService.viewPromotions(
  //       //   [this.proposal],
  //       //   this.position,
  //       //   this.products
  //       // );
  //       analyticsService.viewProducts(
  //         this.products,
  //         this.proposal.name ||
  //           this.proposal.descr ||
  //           this.proposal.imgDescription
  //       );
  //     }
  //   },
  //   handleClick(product) {
  //     analyticsService.clickProduct(
  //       product,
  //       this.proposal.name ||
  //         this.proposal.descr ||
  //         this.proposal.imgDescription
  //     );
  //   }
  // }
};
</script>
