import BannerList from "./BannerList.vue";
import BannerSlider from "./BannerSlider.vue";
import BannerSliderHeader from "./BannerSliderHeader.vue";
import CrmProductSlider from "./CrmProductSlider.vue";
import ProductSlider from "./ProductSlider.vue";
import ProductTab from "./ProductTab.vue";
import StaticImage from "./StaticImage.vue";
import StaticText from "./StaticText.vue";
import Tutorial from "./Tutorial.vue";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import { setStylesBasedOnBreakpoints } from "@/components/category/templateHelper";

export default {
  install: app => {
    app.component("category-block", {
      functional: true,
      name: "category-block",
      props: {
        target: {
          type: Object,
          required: false
        },
        position: {
          type: String,
          required: false
        },
        config: {
          type: Object,
          required: false
        },
        container: {
          type: Boolean,
          default: true
        },
        productId: {
          type: Number,
          required: false
        },
        categoryId: {
          type: Number,
          required: false
        }
      },
      render: function(createElement, context) {
        let cfg;
        if (context.props.config) {
          cfg = context.props.config;
        } else if (context.props.position) {
          cfg = context.props.target?.categoryBlocks?.find(
            categoryBlock =>
              categoryBlock.templateBlock.codInt == context.props.position
          );
        } else {
          cfg = context.props.target?.categoryBlocks?.[0];
        }
        if (cfg) {
          // const cmp = defineAsyncComponent({
          //   loader: () => import("@/components/categoryBlock/BannerSlider.vue")
          // });
          let cmp;

          //   = () =>
          //     import(
          //       `@/components/categoryBlock/${cfg.templateBlockType.modeName}.vue`
          //     );
          switch (cfg.templateBlockType.modeName) {
            case "BannerList":
              cmp = BannerList;
              break;
            case "BannerSlider":
              cmp = BannerSlider;
              break;
            case "BannerSliderHeader":
              cmp = BannerSliderHeader;
              break;
            case "CrmProductSlider":
              cmp = CrmProductSlider;
              break;
            case "ProductSlider":
              cmp = ProductSlider;
              break;
            case "ProductTab":
              cmp = ProductTab;
              break;
            case "StaticImage":
              cmp = StaticImage;
              break;
            case "StaticText":
              cmp = StaticText;
              break;
            case "Tutorial":
              cmp = Tutorial;
              break;
          }

          // if (cmp) {
          //   const id = `category-block-${cfg.categoryBlockId}`;
          //   return createElement(cmp, {
          //     props: {
          //       id: id,
          //       config: cfg,
          //       position: context.props.position,
          //       container: context.props.container,
          //       productId: context.props.productId,
          //       categoryId: context.props.categoryId
          //     },
          //     staticClass: context.data.staticClass + " " + context.data.class,
          //     attrs: {
          //       style: context.data.attrs.style,
          //       class: context.data.attrs.class
          //     }
          //   });
          // }
          if (cmp) {
            const id = `category-block-${cfg.categoryBlockId}`;
            const componentName = kebabCase(cfg.templateBlockType.modeName);
            let content = [];
            let style = app.$ebsn.meta(
              cfg,
              `categoryblocktype_${cfg.templateBlockType.modeName}.CSS_STYLE`
            );
            if (style) {
              let value = style.replace(
                /([^\r\n,{};(?!@)]+)(,|{)/g,
                `#${id}` + " " + "$1$2"
              );
              content.push(
                createElement(
                  "style",
                  {
                    attrs: {
                      type: "text/css"
                    }
                  },
                  value
                )
              );
            }
            content.push(
              createElement(cmp, {
                props: {
                  id: id,
                  config: cfg,
                  container: context.props.container,
                  productId: context.props.productId,
                  categoryId: context.props.categoryId
                },
                style: createStyle(cfg, cfg.templateBlockType.modeName)
              })
            );
            if (context.props.container) {
              content = createElement("div", { staticClass: "container" }, [
                content
              ]);
            }
            let paramClass = " ";
            if (context.data.attrs?.class) {
              paramClass += context.data.attrs.class + " ";
            }
            if (context.data.attrs?.staticClass) {
              paramClass += context.data.attrs.staticClass + " ";
            }
            if (context.data.class) {
              paramClass += context.data.class + " ";
            }
            if (context.data.staticClass) {
              paramClass += context.data.staticClass + " ";
            }

            // set specific style for the category block
            let ebsnStyle;
            if (context.props.categoryBlockStyle) {
              ebsnStyle = createElement("ebsnStyle", {
                props: {
                  target: {
                    metaData: {
                      css: setStylesBasedOnBreakpoints(
                        context.props.categoryBlockStyle,
                        `#${id}`
                      )
                    }
                  },
                  path: "css"
                }
              });
            }

            return createElement(
              "div",
              {
                attrs: {
                  id: id
                },
                key: context.data.key,
                staticClass: `${componentName} ${id} ${paramClass}`,
                on: {
                  hide: function(event, a) {
                    console.log(event);
                    console.log(a);
                  }
                }
              },
              [ebsnStyle, content]
            );
          }
        }
        return null;
        function createStyle(config, optionsName) {
          let style = {};
          let backgroundColor = get(
            config,
            `metaData.categoryblocktype_${optionsName}.BACKGROUND_COLOR`
          );
          if (backgroundColor) {
            style.backgroundColor = backgroundColor;
          }
          return style;
        }
      }
    });
  }
};
