<template>
  <div class="customer-votes-container primary--text" :class="contentClass">
    <div
      v-if="!hasBoughtProduct"
      class="has-bought-legend text-body-2 font-weight-bold mb-1"
      v-html="$t('product.rating.customerVotes.legend')"
    ></div>
    <div class="rate-product" v-if="hasBoughtProduct">
      <span class="mt-2 text-body-1 font-weight-bold text-uppercase">
        {{
          userHasVoted
            ? $t("product.rating.customerVotes.userVote")
            : $t("product.rating.customerVotes.userOpinion")
        }}
      </span>
      <!-- <div class="text-caption desc" v-if="!userHasVoted">
        {{ $t("product.rating.customerVotes.review") }}
      </div> -->
      <div
        class="d-flex align-center rating"
        :class="userHasVoted ? 'mb-2' : ''"
      >
        <v-rating
          dense
          empty-icon="$ratingEmpty"
          full-icon="$ratingFull"
          half-icon="$ratingHalf"
          color="promo"
          hover
          length="5"
          :readonly="userHasVoted"
          v-model="ratingValue"
          class="d-flex"
        >
        </v-rating>
        <v-btn
          v-if="!userHasVoted"
          color="primary"
          depressed
          class="btn-vote my-2 ml-2"
          :disabled="ratingValue === 0"
          @click="setRating()"
        >
          {{ $t("product.rating.customerVotes.voteBtn") }}
        </v-btn>
      </div>
    </div>
    <div class="product-customers-votes-container mt-2">
      <!-- <h4 class="title text-h1 font-weight-bold line-height-13">
        {{ $t("product.rating.customerVotes.customerReview") }}
      </h4> -->
      <div class="customers-votes" v-if="currentRatings">
        <div
          class="vote-bar d-flex aling-items"
          v-for="(rate, index) in productVoteRatings"
          :key="index"
        >
          <span class="stars grey--text mr-1 font-weight-bold">{{
            rate.rating
          }}</span>
          <v-icon color="promo" small>$ratingFull</v-icon>
          <v-progress-linear
            class="mt-2 mx-3"
            height="6"
            :value="(rate.numRating / currentRatings.total) * 100"
          ></v-progress-linear>

          <span class="numRating font-weight-bold">{{ rate.numRating }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.customer-votes-container {
  .customers-votes {
    max-width: 250px;
  }
  .rate-product {
    border: 1px solid var(--v-grey-lighten1);
    width: fit-content;
    padding: 10px 20px 0 20px;
  }
}
</style>
<script>
import RatingService from "~/service/ratingService";

export default {
  name: "ProductCustomerVotes",
  props: {
    productId: { type: Number, required: true },
    contentClass: { type: String, default: "" }
  },
  components: {},
  data() {
    return {
      productVoteMaxRating: global.config.productVoteMaxRating,
      hasBoughtProduct: null,
      userHasVoted: false,
      userVoteValue: 0,
      ratingValue: 0,
      // points: [],
      currentRatings: null
    };
  },
  computed: {
    productVoteRatings() {
      let result = [];
      for (let i = this.productVoteMaxRating - 1; i >= 0; i--) {
        let rating = this.currentRatings.votes.find(
          rate => rate.rating === i + 1
        );
        result.push(
          rating
            ? rating
            : {
                rating: i + 1,
                numRating: 0
              }
        );
      }
      return result;
    }
  },
  methods: {
    handleRatingChange(item) {
      if (!this.userHasVoted) {
        this.ratingValue = item.index + 1;
      }
    },
    async setRating() {
      let res = await RatingService.setRating(this.productId, this.ratingValue);
      if (res.value == true) {
        this.userHasVoted = true;
        this.$emit("submit", true);
      } else {
        this.$emit("submit", false);
      }
    },
    getPointIconForVote(point) {
      if (point > this.product.ratingSummary.avg) {
        if (point <= Math.ceil(this.product.ratingSummary.avg)) {
          return "half";
        }
        return "empty";
      }
      if (point <= Math.floor(this.product.ratingSummary.avg)) {
        return "full";
      }
    },
    async getRatingDetail(productId) {
      let res = await RatingService.getRatingDetail(productId);
      if (res) {
        this.hasBoughtProduct = res.value.buyed;
        this.currentUserVoteDetail = this.getLatestUserVote(
          res.value.voteDetails
        );
      }

      if (this.currentUserVoteDetail) {
        this.userHasVoted = true;
        this.ratingValue = this.currentUserVoteDetail.rating;
        // this.updateRating();
      }
    },
    async getRating(productId) {
      let res = await RatingService.getRating(productId);
      if (res) {
        this.currentRatings = res.value;

        // $rootScope.$broadcast(
        //   "updateProductRating",
        //   $scope.currentRatings,
        //   $scope.product.productId
        // );
      }
    },
    // updateRating() {
    //   this.points = [];
    //   for (let i = 0; i < this.productVoteMaxRating; i++) {
    //     this.points.push({
    //       filled: i < this.ratingValue
    //     });
    //   }
    // },
    setVotesForRating(index) {
      let votes = this.currentRatings.votes;
      let rating = votes.find(function(v) {
        return v.rating === index;
      });
      return rating ? rating.numRating : 0;
    },
    getLatestUserVote(voteDetails) {
      // step 1: noteTypeId === 0 & active === true
      let approvedVotes = voteDetails.filter(function(d) {
        return d.noteTypeId === 0 && d.active;
      });
      // recupero il più recente (approvalDate)
      return approvedVotes.sort(function(a, b) {
        return new Date(b.approvalDate) - new Date(a.approvalDate);
      })[0];
    }
  },
  created() {
    // for (var i = 0; i < this.productVoteMaxRating; i++) {
    //   this.points.push(i);
    // }
    this.getRatingDetail(this.productId);
    this.getRating(this.productId);
  }
};
</script>
