<template>
  <ProductListSlider
    :config="config"
    :products="products"
    :categoryBlockName="'CrmProductSlider'"
    v-intersect.once="handleView"
    @selectProduct="handleClick"
  />
</template>
<style lang="scss">
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
.product-slider {
  .proposal-img {
    margin-bottom: 10px;
  }
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 380px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }
  h2 {
    margin-bottom: 10px;
    // font-size: 28px;
    letter-spacing: 0.45px;
    color: #3c3c3c;
    font-weight: normal;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      .v-image {
        min-height: 110px;
      }
    }
    .proposal-card {
      height: 285px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
  }
  .firstCard {
    float: left;
  }
}
</style>
<script>
// import ProductCard from "@/components/product/ProductCard.vue";
import ProductListSlider from "./elements/ProductListSlider.vue";

// import categoryBlockType from "./categoryBlockType";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "@/commons/service/analyticsService";
import get from "lodash/get";

export default {
  name: "CrmProductListSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    },
    productId: {
      type: Number,
      required: false
    },
    categoryId: {
      type: Number,
      required: false
    }
  },
  mixins: [
    deliveryReactive
    //  categoryBlockType
  ],
  components: {
    // ProductCard,
    ProductListSlider
  },
  data() {
    return {
      products: [],
      categoryBlockName: this.$options.name,
      crmMap: {
        "220": "UpSelling Dettaglio Prodotto",
        "25": "Prodotti In Promo 1 Livello",
        "223": "CrossSelling Dettaglio prodotto",
        "26": "Top Selling Globale",
        "27": "Top Selling 1 livello",
        "29": "I miei preferiti",
        "1000": "Visti di recente",
        "21": "Suggeriti per te"
      },
      swiperDefaultOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#crm-list-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#crm-list-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#crm-list-slider-next-${this.config.categoryBlockId}`
        },
        breakpoints: {
          0: {
            slidesPerView: 1
          },
          600: {
            slidesPerView: 2
          },
          960: {
            slidesPerView: 3
          },
          1264: {
            slidesPerView: 4
          },
          1900: {
            slidesPerView: 6
          }
        }
      }
    };
  },

  methods: {
    async reload() {
      this.products = [];

      const algorithm = get(
        this.config,
        "metaData.categoryblocktype_CrmProductSlider.ALGORITHM"
      );

      if (algorithm) {
        let params = {
          layout: algorithm,
          limit: get(
            this.config,
            "metaData.categoryblocktype_CrmProductSlider.PRODUCT_LIMIT",
            12
          ),
          shuffle: get(
            this.config,
            "metaData.categoryblocktype_CrmProductSlider.PRODUCT_RANDOM",
            false
          ),
          promo: get(
            this.config,
            "metaData.categoryblocktype_CrmProductSlider.PROMO",
            false
          )
        };
        if (this.productId) params.product_id = this.productId;
        if (this.categoryId) params.category_id = this.categoryId;

        let response = await ProductService.adv(params);
        if (response.products) {
          this.products = response.products;
        }
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    },
    getName() {
      let name = get(
        this.config,
        "metaData.categoryblocktype_CrmProductSlider.TITLE"
      ).trim();
      if (!name) {
        const algorithm = get(
          this.config,
          "metaData.categoryblocktype_CrmProductSlider.ALGORITHM"
        );

        name = this.crmMap[algorithm];
        if (!name) {
          name = "CrmProductSlider";
        }
      }
      return name;
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, this.getName());
      }
    },
    //     handleView(entries, observer, isIntersecting) {
    //   if (isIntersecting && this.proposal?.length > 0) {
    //     // analyticsService.viewPromotions(
    //     //   [this.proposal],
    //     //   this.position,
    //     //   this.products
    //     // );
    //     analyticsService.viewProducts(
    //       this.products,
    //       this.proposal.name ||
    //         this.proposal.descr ||
    //         this.proposal.imgDescription
    //     );
    //   }
    // },
    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.getName(),
        payload.index
      );
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.reload();
  }
};
</script>
