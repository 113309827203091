<template>
  <v-menu
    class="selectOptionsMenu mx-1"
    top
    left
    :attach="`#product-card-${product.slug}`"
    v-if="selectOptionsArray.length"
    :position-x="0"
    min-width="65%"
    max-width="97%"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        outlined
        v-on="on"
        depressed
        :small="size == 'small' ? true : false"
        :height="size == 'large' ? 45 : 28"
        color="white"
        min-width="42"
        class="selectOptions ml-1"
        @click.prevent.stop="setSelectOptions"
      >
        <v-icon :small="size == 'small' ? true : false" color="primary"
          >$settings</v-icon
        >
      </v-btn>
    </template>
    <v-list
      dense
      v-for="selectOption in selectOptionsArray"
      :key="selectOption.name"
      @click.prevent.stop
      class="text-caption"
    >
      <v-list-item-title
        v-if="selectOptionsArray.length > 1"
        class="text-caption px-4 font-weight-bold"
      >
        {{ selectOption.name }}
      </v-list-item-title>
      <v-list-item
        v-for="(option, idx) in selectOption.options"
        :key="idx"
        @click.prevent="onOptionChanged(selectOption, option)"
      >
        {{ option.key }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style lang="scss">
.selectOptionsMenu {
  z-index: 12;
}
</style>
<script>
export default {
  name: "SelectedOptionsMenu",
  props: {
    product: { type: Object, required: true },
    selectOptionsArray: { type: Array, required: false },
    size: { type: String, default: "small" }
  },
  components: {},
  data() {
    return {
      selectedOptions: {},
      componentKey: 0,
      key: 0
    };
  },
  methods: {
    onOptionChanged(selectOption, option) {
      this.$emit("onOptionChanged", {
        selectOption: selectOption,
        option: option
      });
    },
    setSelectOptions() {
      this.$emit("setSelectOptions");
    }
  }
};
</script>
