<template>
  <v-card
    rounded="sm"
    :href="link"
    @click.prevent="handleLink"
    elevation="0"
    class="proposal-btn pa-2"
    :style="style"
  >
    <img
      :src="proposal.img"
      :alt="proposal.imgAlt"
      :title="proposal.imgDescription"
    />
    <div class="btn-label" v-if="proposal.descr" v-html="proposal.descr"></div>
  </v-card>
</template>
<style lang="scss">
.proposal-btn {
  // width: 108px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 54px;
    height: "auto";
    max-height: 100%;
  }
  .btn-label {
    font-size: 12px;
    white-space: nowrap;
    text-transform: uppercase;
    overflow: hidden;
    color: var(--v-primary-base);
    font-weight: 700;
    letter-spacing: 0;
    max-width: 100%;
    @media (max-width: 320px) {
      white-space: normal;
      text-align: center;
    }
  }
}
</style>
<script>
import Vue from "vue";
import banner from "~/mixins/banner";
export default {
  name: "ProposalButton",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    style() {
      let style = {};
      let backgroundColor = Vue.$ebsn.meta(
        this.config,
        "metaData.category_proposal_type.BG_COLOR",
        "var(--v-secondary-lighten2)"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      return style;
    }
  }
};
</script>
