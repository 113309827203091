import axios from "~/plugins/axios";
import { defaultParams } from "./defaultParams";

export default {
  getRating(productId) {
    return axios
      .get(`/ebsn/api/rank/product/${productId}/rating`, {
        params: { ...defaultParams() }
      })
      .then(response => response.data.data)
      .catch(error => error);
  },
  getRatingDetail(productId) {
    return axios
      .get(`/ebsn/api/rank/product/${productId}/bought`, {
        params: { ...defaultParams() }
      })
      .then(response => response.data.data)
      .catch(error => error);
  },
  setRating(productId, rating, title, comment) {
    let params = {};

    params.rating = rating ? rating : 1;
    params.title = title ? title : "";
    params.comment = comment ? comment : "";

    return axios
      .post(`/ebsn/api/rank/product/${productId}/vote`, params, {
        ...defaultParams()
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data;
        } else {
          throw new Error(data.data.response.errors[0].error);
        }
      });
  },
  listUserVotes(listType = "to-vote", page = 1, pageSize = 24) {
    let params = { list_type: listType, page: page, page_size: pageSize };

    return axios
      .get("/ebsn/api/rank/list-user-votes", { params: params })
      .then(data => {
        return data.data.data;
      })
      .catch(error => error);
  }

  // https://www.tigros.it/ebsn/api/rank/list-user-votes?page=1&page_size=25&list_type=to-vote
};
