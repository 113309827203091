<template>
  <v-card
    rounded="sm"
    outlined
    elevation="0"
    class="proposal-card-horizontal"
    :style="style"
    v-intersect.once="handleView"
  >
    <v-row no-gutters>
      <v-col cols="4">
        <v-img
          :src="src"
          :alt="proposal.imgAlt"
          :title="proposal.imgDescription"
          height="100%"
        />
      </v-col>
      <v-col cols="8" class="d-flex flex-column">
        <v-card-title v-if="proposal.descr"
          ><h3>{{ proposal.descr }}</h3></v-card-title
        >
        <v-card-text
          v-if="proposal.content"
          v-html="proposal.content"
          class="d-block"
        >
        </v-card-text>
        <v-spacer />
        <v-card-actions
          v-if="link && $ebsn.meta(proposal, 'category_proposal_type.SHOW_BTN')"
        >
          <v-btn
            :href="link"
            @click.prevent="handleLink"
            color="secondary"
            block
            depressed
          >
            {{
              $ebsn.meta(
                proposal,
                "category_proposal_type.BTN_TEXT",
                "scopri di più"
              )
            }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.proposal-card-horizontal {
  .v-card__title {
    padding: 8px;
    h3 {
      font-size: 20px;
    }
  }
  p {
    margin-bottom: 0;
  }
}
</style>
<script>
import get from "lodash/get";
import banner from "~/mixins/banner";
import Vue from "vue";
export default {
  name: "ProposalCardHorizontal",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    style() {
      let style = {};
      let backgroundColor = Vue.$ebsn.meta(
        this.config,
        "category_proposal_type.COLOR"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      //  else {
      //   style.backgroundColor = "var(--v-primary-lighten2)";
      // }
      return style;
    },
    src() {
      let isXs = this.$vuetify.breakpoint.xsOnly;
      let prop =
        "metaData.category_proposal_type." +
        (isXs ? "IMAGE_MOBILE" : "IMAGE_DESKTOP");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
