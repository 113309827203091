import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "./i18n";
import "@/scss/_fonts.scss";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  theme: {
    // disable: false,
    light: true,
    options: {
      customProperties: true
      // variations: false
    },
    themes: {
      light: {
        logo: {
          default: "/logo/logo.svg",
          mobile: "/logo/logo-mobile.svg"
        },
        default: {
          base: "#013067",
          darken1: "#4A4A4A", // poli
          darken2: "#3C3C3C" // poli
        },
        primary: {
          base: "#013067",
          lighten1: "#6C6AAF",
          lighten2: "#C8C3E8",
          darken1: "#242167",
          darken2: "#020034"
        },
        secondary: {
          base: "#ffcc00",
          lighten1: "#FFF08E",
          lighten2: "#FCF7D7",
          darken1: "#E5C704",
          darken2: "#9D8905",
          darken3: ""
        },
        white: "#FFFFFF",
        black: "#000000",
        accent: { base: "#CBCAD8" },
        grey: {
          base: "#A4A3AF",
          lighten1: "#CBCAD8",
          lighten2: "#E4E3F0",
          lighten3: "#EEEEEE",
          darken1: "#66666D",
          darken2: "#3D3D41"
        },
        promo: "#e42313",
        news: "#e42313", // no
        error: "#ff3333",
        warning: "#ed7c20",
        green: "#006c31",
        success: "#51bf02"
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    values: {
      // cardArrow: "icon-card-arrow",
      // clock: "icon-clock"
      // delete: "icon-delete",
      keep: "icon-keep",
      hamburgerMenu: "icon-hamburger-menu",
      bulb: "icon-bulb",
      article: "icon-article",
      whatsapp: "icon-whatsapp",
      lista: "icon-lista",
      play: "icon-play",
      info: "icon-info1",
      reparti: "icon-reparti",
      sfoglia: "icon-sfoglia",
      scarica: "icon-scarica",
      ricetta: "icon-ricetta",
      arrowBack: "icon-arrow-back",
      arrowDownward: "icon-arrow-downward",
      arrowForward: "icon-arrow-forward",
      arrowTopLeft: "icon-arrow-top-left",
      arrowUpward: "icon-arrow-upward",
      cancel: "icon-close",
      cart: "icon-cart",
      checkboxIndeterminate: "icon-checkbox-indeterminate",
      checkboxOff: "icon-checkbox-off",
      checkboxOn: "icon-checkbox-on",
      clear: "icon-close",
      close: "icon-close",
      complete: "icon-complete",
      creditcard: "icon-creditcard",
      day: "icon-day",
      delete: "icon-garbage",
      delimiter: "icon-delimiter", // for carousel
      delivery: "...",
      dots: "icon-dots",
      dropdown: "icon-dropdown",
      edit: "icon-edit",
      editcalendar: "icon-edit-calendar",
      error: "icon-warning",
      euro: "icon-euro",
      expand: "icon-expand",
      expandLess: "icon-expand-less",
      eye: "icon-eye-full",
      eyeOff: "icon-eye-off-full",
      file: "icon-file",
      filter: "icon-filter",
      first: "icon-first",
      geolocate: "icon-location",
      heart: "icon-heart",
      heartfull: "icon-heartfull",
      home: "icon-home",
      last: "icon-last",
      like: "icon-like",
      loading: "...",
      location: "icon-location",
      locationDashboard: "icon-location",
      locker: "...",
      logout: "icon-logout",
      menu: "icon-menu-site",
      menuEcommerce: "icon-menu-ecommerce",
      microphone: "icon-microphone",
      minus: "icon-minus",
      mood: "...",
      next: "icon-next",
      night: "icon-night",
      noPackage: "...",
      orders: "icon-orders",
      package: "...",
      percent: "icon-percent",
      pickup: "...",
      plus: "icon-add",
      power: "icon-power",
      prev: "icon-prev",
      print: "icon-print",
      products: "...",
      profile: "icon-profile",
      profileDashboard: "icon-profile",
      qrCode: "icon-qr-code",
      barcode: "icon-barcode-scan",
      radioOff: "icon-radio-unchecked",
      radioOn: "icon-radio-checked",
      ratingEmpty: "icon-rating-full",
      ratingFull: "icon-rating-full",
      ratingHalf: "icon-rating-half",
      receipt: "icon-receipt",
      search: "icon-search",
      fastSearch: "icon-fast-search",
      sort: "icon-ordina-by",
      storefront: "...",
      subgroup: "icon-expand",
      success: "icon-complete",
      tag: "icon-tag",
      unfold: "icon-unfold",
      warning: "icon-warning",
      services: "icon-services",
      gift: "icon-gift",
      regulation: "icon-regulation",
      pack: "icon-pack",
      box: "icon-box",
      starFull: "icon-star",
      starHalf: "icon-star-half",
      star: "icon-star-outline",
      favourite: "icon-star",
      work: "icon-work",
      copy: "icon-copy",
      linkedin: "icon-linkedin",
      facebook: "icon-fb",
      googlePlus: "icon-G",
      youtube: "icon-youtube",
      elder: "icon-elder-address",
      settings: "icon-settings"
    }
  }
});
