function calcHeightStyle(minHeightsObj, elementIdentifier) {
  try {
    let breakpoints = {
      xs: { minWidth: 0, maxWidth: 599 },
      sm: { minWidth: 600, maxWidth: 959 },
      md: { minWidth: 960, maxWidth: 1263 },
      lg: { minWidth: 1264, maxWidth: 1903 },
      xl: { minWidth: 1904 }
    };
    if (minHeightsObj) {
      let minHeightStyle = Object.keys(minHeightsObj).reduce(
        (cumulativeString, key) => {
          let breakpoint = breakpoints[key];
          let minHeight = minHeightsObj[key];
          let breakpointMinHeightCss = "";
          if (minHeight) {
            let heightStr;
            if (typeof minHeight === "number" && minHeight > 0) {
              heightStr = minHeight + "px";
            }
            if (typeof minHeight === "string" && minHeight != "") {
              heightStr = minHeight;
            }
            if (heightStr) {
              breakpointMinHeightCss = breakpoint.maxWidth
                ? `@media screen and (min-width: ${breakpoint.minWidth}px) and (max-width: ${breakpoint.maxWidth}px){ ${elementIdentifier} {height: ${heightStr}} }
                      `
                : `@media screen and (min-width: ${breakpoint.minWidth}px){ ${elementIdentifier} {height: ${heightStr}} }
                      `;
            }
          }
          return cumulativeString + breakpointMinHeightCss;
        },
        ""
      );
      return minHeightStyle;
    } else {
      return null;
    }
  } catch (err) {
    console.log("calcHeightStyle", err);
    return null;
  }
}
function setStylesBasedOnBreakpoints(stylesObj, elementIdentifier) {
  try {
    const breakpoints = {
      xs: { minWidth: 0, maxWidth: 599 },
      sm: { minWidth: 600, maxWidth: 959 },
      md: { minWidth: 960, maxWidth: 1263 },
      lg: { minWidth: 1264, maxWidth: 1903 },
      xl: { minWidth: 1904 }
    };

    if (stylesObj) {
      const cssStyleToReturn = Object.keys(stylesObj).reduce(
        (cumulativeCssString, key) => {
          const breakpoint = breakpoints[key];
          const styleObj = stylesObj[key];
          let composedBreakpointStyleStr = "";
          if (styleObj) {
            let styleStr = "";
            for (const p in styleObj) {
              styleStr += `${p}: ${styleObj[p]}; `;
            }
            composedBreakpointStyleStr = breakpoint.maxWidth
              ? `@media screen and (min-width: ${breakpoint.minWidth}px) and (max-width: ${breakpoint.maxWidth}px){ ${elementIdentifier} { ${styleStr}} }
                      `
              : `@media screen and (min-width: ${breakpoint.minWidth}px){ ${elementIdentifier} { ${styleStr} }
                      `;
          }
          return cumulativeCssString + composedBreakpointStyleStr;
        },
        ""
      );
      return cssStyleToReturn;
    } else {
      return null;
    }
  } catch (err) {
    console.log("calcHeightStyle", err);
    return null;
  }
}

export { calcHeightStyle, setStylesBasedOnBreakpoints };
