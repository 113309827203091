<template>
  <div
    v-if="config.proposals && config.proposals.length > 0"
    :id="`banner-slider-${config.categoryBlockId}`"
    class="banner-slider"
    :style="style"
  >
    <div :class="{ container: container }">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerSlider.TITLE"
        tag="h2"
      />
      <ebsn-meta
        :target="config"
        path="categoryblocktype_BannerSlider.DESCRIPTION"
        tag="div"
      />

      <div class="slider banner-slider">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide v-for="proposal in config.proposals" :key="proposal.id">
            <component
              :is="modelName"
              :proposal="proposal"
              :position="config.templateBlock.codInt"
            />
          </swiper-slide>
        </swiper>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_BannerSlider.SHOW_BULLET',
              true
            ) && config.proposals.length > 1
          "
          :id="`banner-pagination-${config.categoryBlockId}`"
          class="swiper-pagination"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_BannerSlider.SHOW_ARROWS',
              false
            ) && config.proposals.length > 1
          "
          :id="`banner-slider-prev-${config.categoryBlockId}`"
          class="swiper-button-prev"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_BannerSlider.SHOW_ARROWS',
              false
            ) && config.proposals.length > 1
          "
          :id="` banner-slider-next-${config.categoryBlockId}`"
          class="swiper-button-next"
        ></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.banner-slider {
  .swiper-slide {
    height: auto;
    .proposal-card.v-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      .v-img {
        max-height: 180px;
      }
    }
  }
}
</style>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import ProposalCardHorizontal from "./elements/ProposalCardHorizontal.vue";
import ProposalCardResponsive from "./elements/ProposalCardResponsive.vue";
import ProposalCard from "./elements/ProposalCard.vue";
import ProposalButton from "./elements/ProposalButton.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

export default {
  name: "BannerSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalImage,
    ProposalCardHorizontal,
    ProposalCardResponsive,
    ProposalCard,
    ProposalButton
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#banner-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#banner-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#banner-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
    }
  }
};
</script>
