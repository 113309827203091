<template>
  <div class="product-rating-container">
    <div v-if="product.productInfos.RATE_ENABLED == 'true'" class="user-rating">
      <div class="avg d-flex justify-space-between align-center">
        <template v-if="product.ratingSummary.total > 0">
          <v-rating
            empty-icon="$ratingFull"
            full-icon="$ratingFull"
            half-icon="$ratingHalf"
            hover
            color="promo"
            dense
            half-increments
            length="5"
            readonly
            :value="product.ratingSummary.avg"
            class="d-flex"
          >
          </v-rating>
          <span class="avg-total-count ml-2 ml-md-1 text-caption grey--text">
            {{ product.ratingSummary.avg | number(1) }}|{{
              product.ratingSummary.total
            }}
            {{ $t("product.rating.productRating.review") }}
          </span>
        </template>
        <v-spacer></v-spacer>
        <!-- :to="{ name: 'Product', params: { slug: product.slug } }" -->
        <v-btn
          color="primary"
          v-if="showButton"
          text
          @click.prevent.stop=""
          @click="openVoteModal()"
        >
          {{ $t("product.rating.productRating.voteBtn") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.product-rating-container {
  .user-rating .v-rating button {
    font-size: 16px !important;
  }
}
.product-card {
  .product-rating-container {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>
<script>
import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";

export default {
  name: "ProductRating",
  props: {
    product: { type: Object, required: true },
    showButton: { type: Boolean, default: false }
  },
  data() {
    return {
      productVoteMaxRating: global.config.productVoteMaxRating
    };
  },
  methods: {
    async openVoteModal() {
      let res = await this.$dialog.show(ProductCustomerVotes, {
        waitForResult: true,
        contentClass: "customer-votes-modal pa-5",
        productId: this.product.productId
      });
      console.log(res);
    }
  }
};
</script>
