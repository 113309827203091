<template>
  <div class="login login-container fill-height mt-1 white">
    <v-container fluid fill-height>
      <v-layout>
        <v-flex xs12>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent.stop="handleSubmit"
          >
            <v-card class="elevation-0 px-0 px-sm-7 pb-5">
              <div
                class="text-h1 line-height-1 default--text text-center mt-6 mb-2"
              >
                {{ $t("login.title") }}
              </div>
              <v-spacer></v-spacer>
              <v-card-text>
                <v-text-field
                  color="primary"
                  v-model="email"
                  :error-messages="errors"
                  type="email"
                  :label="$t('login.email')"
                  autocomplete="off"
                  class="mb-2"
                  required
                  dense
                  outlined
                  clearable
                ></v-text-field>
                <v-text-field
                  color="primary"
                  v-model="password"
                  :label="$t('login.password')"
                  autocomplete="off"
                  :append-icon="showPassword ? '$eye' : '$eyeOff'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  @click:append="toggleShowPassword"
                  required
                  dense
                  outlined
                  clearable
                ></v-text-field>

                <div class="d-flex flex-column remember-password">
                  <v-checkbox
                    color="primary"
                    class="mt-0 mb-4 align-self-center"
                    hide-details
                    v-model="rememberMe"
                  >
                    <template v-slot:label>
                      <div class="text-caption">
                        {{ $t("login.rememberOnDevice") }}
                      </div>
                    </template>
                  </v-checkbox>
                  <v-btn
                    :loading="loading"
                    type="submit"
                    color="primary"
                    large
                    block
                    depressed
                  >
                    {{ $t("login.buttonLogin") }}
                  </v-btn>
                  <ResponseMessage :response="response" class="mt-3" />
                  <v-btn
                    v-if="isCordova"
                    :loading="loadingCardCode"
                    class="mt-0 mb-1"
                    color="secondary"
                    large
                    block
                    depressed
                    @click="scanCard()"
                  >
                    {{ $t("login.buttonScanCard") }}
                  </v-btn>
                  <div class="default--text mt-2 font-weight-bold text-right">
                    <a
                      @click="goToResetPassword"
                      class="text-decoration-underline text-caption"
                      :class="$vuetify.breakpoint.xs ? 'default--text' : ''"
                    >
                      {{ $t("login.rememberPassword") }}
                    </a>
                  </div>
                </div>

                <span
                  v-if="enableRegistration"
                  class="d-block text-center mt-7 mb-2 primary--text text-h2 line-height-1 mt-2 font-weight-bold"
                  >{{ $t("login.socialLoginText") }}</span
                >
                <div
                  v-if="enableRegistration"
                  class="social-login d-flex justify-space-between"
                >
                  <!-- <div class="w-50 pr-3"> -->
                  <div class="w-100">
                    <v-btn
                      @click.stop.prevent="socialLoginGoogle()"
                      outlined
                      large
                      block
                      depressed
                      color="primary"
                      class="google-btn"
                    >
                      <img
                        class="mr-2"
                        height="20"
                        width="20"
                        src="/img_layout/social/logo-google.svg"
                        alt="logo google"
                      />
                      <span>{{ $t("login.buttonGoogle") }}</span>
                    </v-btn>
                  </div>
                  <!-- <div class="w-50 pl-3">
                    <v-btn
                      @click.stop.prevent="facebookLogin()"
                      color="#3D6AD6"
                      depressed
                      large
                      block
                      class="facebook-btn white--text"
                    >
                      <v-icon class="mr-1">$facebook</v-icon>
                      <span>{{ $t("login.buttonFacebook") }}</span>
                    </v-btn>
                  </div> -->
                </div>
              </v-card-text>
              <v-divider v-if="enableRegistration" />
              <v-card-actions
                v-if="enableRegistration"
                class="d-flex flex-column default--text"
              >
                <div class="text-h2">
                  {{ $t("login.messageRegister") }}
                </div>
                <v-btn
                  @click="$emit('submit', false)"
                  class="primary--text"
                  large
                  outlined
                  block
                  depressed
                  :to="{ name: 'RegistrationUser', path: '/registration-user' }"
                >
                  {{ $t("login.buttonRegister") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style lang="scss">
.login-container {
  .default--text {
    a {
      color: var(--v-default-base) !important;
      font-weight: bold !important;
    }
  }
}
</style>

<script>
import ResponseMessage from "@/components/ResponseMessage";
// import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";

import pushNotification from "~/mixins/pushNotification";

import recaptcha from "~/mixins/recaptcha";

import { mapActions, mapGetters } from "vuex";

import SocialService from "~/service/socialService";
// import SecurityService from "~/service/securityService";

import {
  requiredValue,
  isPasswordMinimumLength
} from "~/validator/validationRules";

export default {
  name: "Login",
  props: {
    hideLogo: { type: Boolean, required: false, default: false },
    hideRegistration: { type: Boolean, required: false, default: false },
    redirectParams: { type: Object, required: false }
  },
  mixins: [pushNotification, recaptcha],
  components: { ResponseMessage },
  data() {
    return {
      loading: null,
      loadingCardCode: null,
      showPassword: false,
      email: "",
      password: "",
      rememberMe: true,
      response: {},
      valid: true,
      lazy: true,
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLength()
      ],
      errors: [],
      invalid: null,
      enableRegistration: process.env.VUE_APP_ENABLE_REGISTRATION == "true"
    };
  },
  methods: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapActions({
      doLogin: "cart/doLogin",
      loadCart: "cart/loadCart"
    }),
    goToResetPassword() {
      this.$router.push({
        name: "ResetPasswordRequest"
      });
      this.$emit("submit", false);
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    doFBLogin() {
      var _this = this;
      window.facebookConnectPlugin.login(
        ["public_profile", "email"],
        function(userData) {
          SocialService.facebookLoginConnect(
            userData.authResponse.accessToken,
            true
          ).then(
            function(data) {
              if (data.user.login && data.user.login != "") {
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl = "/dashboard";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
              } else {
                //NUOVO UTENTE
                if (data.data.socialRedirectUrl) {
                  data.data.socialRedirectUrl =
                    "/registration-nocard?social_login=facebook";
                }
                _this.$emit("submit", false);
                global.vm.$router
                  .push({
                    path: data.data.socialRedirectUrl
                  })
                  .catch(err => err);
                // SecurityService.refreshLogin(
                //   data.socialRedirectUrl.indexOf("registration") >= 0
                // ).then(
                //   function() {
                //     this.closeLogin();
                //   },
                //   function() {
                //     this.closeLogin();
                //   }
                // );
              }
            },
            function(error) {
              console.log(error);
            }
          );
        },
        function(error) {
          console.log(error);
        }
      );
    },
    facebookLogin() {
      if (this.isCordova) {
        this.doFBLogin();
      } else {
        let redirectBaseUrl = `${window.location.protocol}//${
          window.location.hostname
        }${
          window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : ""
        }`;
        SocialService.openFacebookLoginPage(redirectBaseUrl);
      }
    },
    socialLoginGoogle() {
      if (this.isCordova) {
        let redirectBaseUrl =
          window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port !== "80" &&
          window.location.port !== "443" &&
          window.location.port &&
          window.location.port !== ""
            ? ":" + window.location.port
            : "");

        let url =
          "https://accounts.google.com/o/oauth2/auth?client_id=" +
          global.config.googleClientId +
          "&redirect_uri=" +
          redirectBaseUrl +
          "/ebsn/api/social-redir/google&prompt=consent&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

        SocialService.handleGoogleOnApp(url, "registration-nocard");
      } else {
        SocialService.openGoogleLoginPage();
      }
      this.$emit("submit", false);
    },
    scanCard() {
      if (window.cordova && window.cordova.plugins.barcodeScanner) {
        try {
          this.loadingCardCode = true;
          // eslint-disable-next-line no-undef
          cordova.plugins.barcodeScanner.scan(
            result => {
              if (result) {
                this.loadingCardCode = false;
                // todo get card code and go to registration
              }
            },
            error => {
              this.loadingCardCode = false;
              console.error("Scanning failed", error);
            },
            {
              showFlipCameraButton: true,
              showTorchButton: true,
              resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              formats:
                this.formats ||
                "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    },
    async handleSubmit() {
      let _this = this;
      let token = null;
      if (this.$recaptchaLoaded) {
        await this.$recaptchaLoaded();
        token = await this.$recaptcha("login");
      }
      if (_this.password.length > 0) {
        try {
          _this.loading = true;
          _this.response = {};
          await _this.doLogin({
            email: _this.email,
            password: _this.password,
            rememberMe: _this.rememberMe,
            token: token
          });
          if (_this.isAuthenticated) {
            global.EventBus.$emit("login");
            _this.$emit("loggedIn", true);
          }
          this.$emit("submit", true);
          if (_this.redirectParams) {
            _this.$router.push({
              name: _this.redirectParams.name,
              path: _this.redirectParams.path,
              params: _this.redirectParams.params
            });
          }
          this.$recaptchaInstance?.hideBadge();
        } catch (response) {
          this.response = response;
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>
