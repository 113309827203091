var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products.length > 0)?_c('div',{staticClass:"product-list-slider",class:_vm.classes,style:(_vm.style),attrs:{"id":`product-list-slider-${_vm.config.categoryBlockId}`}},[_c('v-container',[_c('ebsn-meta',{attrs:{"target":_vm.config,"path":`categoryblocktype_${_vm.name}.TITLE`,"tag":"h2"}}),_c('ebsn-meta',{attrs:{"target":_vm.config,"path":`categoryblocktype_${_vm.name}.DESCRIPTION`,"tag":"div"}}),_c('div',{staticClass:"slider product-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},[(_vm.showCard)?_c('swiper-slide',[_c('product-slider-card',{attrs:{"img":_vm.$ebsn.meta(
                _vm.config,
                'categoryblocktype_' + _vm.name + '.CARD_IMAGE.location'
              ),"text":_vm.$ebsn.meta(_vm.config, 'categoryblocktype_' + _vm.name + '.CARD_TEXT'),"link":{
              name: 'Category',
              params: {
                pathMatch: _vm.$ebsn.meta(
                  this.config,
                  'categoryblocktype_' + _vm.name + '.CATEGORY.slug'
                )
              }
            },"linkLabel":_vm.$ebsn.meta(_vm.config, 'categoryblocktype_' + _vm.name + '.BUTTON_TEXT')}})],1):_vm._e(),_vm._l((_vm.products),function(product,index){return _c('swiper-slide',{key:product.productId},[_c('ProductCard',{attrs:{"product":product},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', { product: product, index: index })}}})],1)})],2),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_BULLETS',
            true
          ) && _vm.products.length > 1
        )?_c('div',{class:`swiper-pagination product-list-pagination-${_vm.config.categoryBlockId}`,attrs:{"id":`product-list-pagination-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_ARROWS',
            false
          ) && _vm.products.length > 1
        )?_c('div',{staticClass:"swiper-button-prev",attrs:{"id":`product-list-slider-prev-${_vm.config.categoryBlockId}`}}):_vm._e(),(
          _vm.$ebsn.meta(
            _vm.config,
            'categoryblocktype_' + _vm.name + '.SHOW_ARROWS',
            false
          ) && _vm.products.length > 1
        )?_c('div',{staticClass:"swiper-button-next",attrs:{"id":`product-list-slider-next-${_vm.config.categoryBlockId}`}}):_vm._e()],1),(_vm.showMore && !_vm.showCard)?_c('div',{staticClass:"text-right show-more"},[_c('v-btn',{staticClass:"primary--text text-none",attrs:{"link":"","text":"","to":{
          name: 'Category',
          params: {
            pathMatch: _vm.$ebsn.meta(
              this.config,
              'categoryblocktype_' + _vm.name + '.CATEGORY.slug'
            )
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t("categoryBlock.productListSlider.showAll"))+" "),_c('v-icon',[_vm._v("$arrowForward")])],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }