<template>
  <v-card
    rounded="sm"
    outlined
    :href="link"
    @click.prevent="handleLink"
    elevation="0"
    class="proposal-card"
    v-intersect.once="handleView"
  >
    <v-img :src="src" :alt="proposal.imgAlt" :title="proposal.imgDescription" />

    <v-card-title v-if="proposal.descr" v-html="proposal.descr"></v-card-title>
    <v-card-text
      class="d-none d-sm-block"
      v-if="proposal.content"
      v-html="proposal.content"
    >
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions v-if="link">
      <v-btn
        :href="link"
        @click.prevent="handleLink"
        color="secondary"
        block
        depressed
      >
        {{
          $ebsn.meta(
            proposal,
            "category_proposal_type.BUTTON_TEXT",
            "Scopri di più"
          )
        }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import get from "lodash/get";
import banner from "~/mixins/banner";
export default {
  name: "ProposalCard",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner],
  computed: {
    src() {
      let isXs = this.$vuetify.breakpoint.xsOnly;
      let prop =
        "metaData.category_proposal_type." +
        (isXs ? "IMAGE_MOBILE" : "IMAGE_DESKTOP");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
