<template>
  <v-card
    outlined
    rounded="md"
    class="product-card"
    :class="[promoClass]"
    :id="`product-card-${product.slug}`"
    :to="{ name: 'Product', params: { slug: product.slug } }"
  >
    <div class="product" @click="$emit('selectProduct')">
      <div class="d-flex justify-end align-center w-100 top">
        <div class="product-badge" v-if="product.badge">
          {{ product.badge.label }}
        </div>
        <v-spacer />
        <ProductAcqLimit :product="product" />
        <v-tooltip left :key="heartKey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click.stop.prevent="addToFavorites"
              @mousedown.stop
              aria-label="Aggiungi ad una lista"
            >
              <v-icon color="primary" v-if="highlight">$heartfull</v-icon>
              <v-icon v-else color="grey">$heart</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("product.addToList") }}</span>
        </v-tooltip>
      </div>
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img align-self-center"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <h2 class="name font-weight-bold">
              {{ product.name }}
            </h2>
            <span class="cod-int text-caption d-none">
              cod. {{ product.codInt }}
            </span>
            <span class="short_descr">
              {{ product.shortDescr }}
            </span>
            <span class="descr">
              {{ product.description }}
              <span
                class="weight-unit"
                v-if="
                  product.productInfos &&
                    product.productInfos.TIPOLOGIA == 'Pezzo' &&
                    product.priceUmDisplay
                "
              >
                - {{ $n(product.priceUmDisplay, "currency") }}/{{
                  product.weightUnitDisplay
                }}
              </span>
            </span>
            <span
              class="option d-flex align-center justify-space-between line-height-1"
              style="white-space: initial;text-align: left;"
              v-for="(value, label) in selectedOptions"
              :key="label"
            >
              <div>
                <i
                  >Preferenza:<b>
                    {{ value
                    }}{{
                      label === "customWeight"
                        ? " " + product.productInfos.WEIGHT_UNIT_SELLING
                        : ""
                    }}</b
                  ></i
                >
              </div>
              <SelectedOptionsMenu
                :product="product"
                :selectOptionsArray="selectOptionsArray"
                :size="'small'"
                @onOptionChanged="onOptionChanged"
              />
            </span>

            <v-spacer />
          </div>
        </div>
        <div v-if="product.available > 0" class="actions" @click.stop.prevent>
          <!--  -->
          <ProductPrice v-if="product.priceDisplay" :product="product" />
          <v-spacer />
          <SelectedOptionsMenu
            v-if="quantity <= 0"
            :product="product"
            :selectOptionsArray="selectOptionsArray"
            :size="'large'"
            @onOptionChanged="onOptionChanged"
            @setSelectOptions="setSelectOptions"
          />
          <ProductQty
            :key="key"
            :selectedOptions="selectedOptions"
            :product="product"
            :item="item"
            :position="position"
          />
        </div>
        <div
          v-else
          class="actions justify-center text-center text-caption error--text font-weight-bold"
        >
          {{ $t("product.notAvailable") }}
        </div>

        <!-- <div>spazio mele</div> -->
        <ProductRating
          v-if="isAuthenticated && product.productInfos.RATE_ENABLED == 'true'"
          :product="product"
          :showButton="true"
        />
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  .v-rating .v-icon {
    font-size: 16px;
  }
  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 17px;
      width: 100px;
      height: 100px;
    }
  }
  .product .description .name {
    font-family: $heading-font-family;
  }
  .promo-wrapper {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
  .cart-item-info {
    margin-bottom: -21px;
  }

  .top {
    height: 26px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-price {
      width: 140px;
      text-align: center;
    }
  }

  .actions {
    .selectOptions {
      margin-right: 3px;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
}
</style>
<script>
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductQty from "./ProductQty.vue";
import ProductRating from "@/components/product/ProductRating.vue";
import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  components: {
    ProductAcqLimit,
    ProductPrice,
    ProductQty,
    ProductRating,
    SelectedOptionsMenu
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.isNew) {
        productClasses.push("new-product");
      }
      if (this.product.badge) {
        productClasses.push(this.product.badge.cssClass);
      }
      return productClasses;
    }
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.value;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    }
  },
  mounted() {
    if (this.product.selectOptions && this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  }
};
</script>
