<template>
  <div>
    <v-container class="timeslot-selector fill-height">
      <v-layout fill-height column>
        <ebsn-meta
          class="text-center"
          :target="category"
          path="category_info.TITLE"
          :default-value="category.name"
          tag="h1"
        ></ebsn-meta>
        <ebsn-meta
          :target="category"
          path="category_info.DESCRIPTION"
          class="text-center text-body-2 mt-2"
        ></ebsn-meta>
        <category-block
          :target="category"
          position="position1"
          class="category-block category-block-1"
          :container="false"
        />
        <div
          class="text-body-2 w-100 d-flex align-center justify-space-between"
        >
          <div>
            <div
              class="text-uppercase font-weight-bold"
              style="line-height: 18px;"
            >
              {{ $t(`timeslots.label.${selectedServiceId}`) }}:
              {{ cart.shippingAddress.addressName }}
              <v-icon
                v-if="editableAddress"
                right
                @click="changeAddress"
                color="grey darken-1"
                :class="{ 'ml-1': !$vuetify.breakpoint.xs }"
                :style="{ 'line-height: 18px': !$vuetify.breakpoint.xs }"
              >
                $edit
              </v-icon>
            </div>
            <div class="address">
              {{
                $t(
                  "navbar.address." +
                    cart.shippingAddress.addressTypeId +
                    ".list",
                  cart.shippingAddress
                )
              }}
            </div>
          </div>
          <img
            class="d-none d-sm-flex"
            width="78"
            height="58"
            contain
            :src="getServiceParams(getDeliveryServiceId).icon"
            :alt="getServiceParams(getDeliveryServiceId).name"
          />
        </div>

        <v-divider></v-divider>
        <div class="text-body-2 font-weight-bold text-uppercase mb-3">
          {{ $t("timeslots.dayLabel") }}
        </div>
        <v-tabs
          hide-slider
          icons-and-text
          v-model="tab"
          height="auto"
          center-active
          slider-size="1"
          show-arrows
          fixed-tabs
          class="days-tabs"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="timeslotDay in days"
            :key="timeslotDay.dateIso"
            @click="selectTimeslotDay(timeslotDay)"
          >
            <div
              class="time-div d-flex flex-column align-center justify-space-around"
            >
              <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
                <span class="day-number font-weight-bold">
                  OGGI
                </span>
              </template>
              <template v-else>
                <span class="day-string font-weight-bold">
                  {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
                </span>
                <span class="day-number font-weight-bold">
                  {{ $dayjs(timeslotDay.dateIso).format("D") }}
                </span>
                <span class="month">
                  {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
                </span>
              </template>
            </div>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>

        <div class="text-body-2 font-weight-bold text-uppercase mb-3">
          {{ $t("timeslots.timeLabel") }}
        </div>

        <!-- <v-row
          no-gutters
          align="center"
          justify="space-between"
          justify-sm="start"
          class="range-buttons mb-5 mb-md-3 mb-lg-5"
        >
          <v-col
            cols="4"
            sm="auto"
            v-for="range in timeslotRange"
            :key="range.id"
            class="d-flex justify-center mr-0 mr-sm-2"
          >
            <v-btn
              depressed
              class="rounded-sm"
              :class="
                rangeTab === range.id
                  ? 'primary white--text active-border'
                  : 'white default--text text--darken-2'
              "
              :min-width="$vuetify.breakpoint.xs ? 'auto' : 120"
              :small="$vuetify.breakpoint.xs"
              data-test="range-btn"
              :value="range.id"
              min-height="38"
              @click="rangeTab = range.id"
            >
              <v-icon
                v-if="range.icon"
                :color="rangeTab === range.id ? 'white' : 'default'"
                >{{ range.icon }}</v-icon
              >
              <span class="range-text">{{ $t(range.text) }}</span>
            </v-btn>
          </v-col>
        </v-row> -->

        <v-tabs-items v-model="tab">
          <v-tab-item
            class="timeslot-container"
            v-for="timeslotDay in days"
            :key="timeslotDay.dateIso"
          >
            <v-row dense v-if="timeslotDay.timeslots.length > 0" no-gutters>
              <v-col
                cols="4"
                sm="3"
                md="2"
                lg="3"
                v-for="timeslot in timeslotDay.timeslots.filter(
                  checkDeliveryDayPeriodId
                )"
                :key="timeslot.timeslotId"
              >
                <v-card class="timeslot-card" flat :disabled="disabled">
                  <div
                    v-on:click="selectTimeslot(timeslot)"
                    class="timeslot-button d-flex justify-center"
                    :class="[
                      timeslot.status,
                      { 'under-lead-time': timeslot.underLeadTime },
                      { 'under-lock-day': timeslot.underLockDay },
                      { 'under-lock-cutoff': timeslot.underLockCutoff },
                      { selected: timeslot.selected }
                    ]"
                  >
                    <span class="lock-icons">
                      <v-icon
                        v-if="
                          timeslot.underLeadTime ||
                            timeslot.underLockDay ||
                            timeslot.underLockCutoff
                        "
                        x-small
                      >
                        $clock
                      </v-icon>
                    </span>
                    <span class="timeslot-time"
                      >{{ timeslot.beginTime }} - {{ timeslot.endTime }}</span
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <p v-else>
              <span
                class="d-block pa-3 secondary text-uppercase font-weight-bold text-center"
                >{{ $t("timeslots.noSlots") }}</span
              ><br />
              <span
                class="d-block pa-3 error--text secondary text-uppercase font-weight-bold text-center"
                >{{ $t("timeslots.noSlotsSuggestion") }}</span
              >
            </p>
          </v-tab-item>
        </v-tabs-items>
        <category-block
          :target="category"
          position="position2"
          class="category-block category-block-2"
          :container="false"
        />
        <!-- <v-spacer></v-spacer> -->
        <v-row class="legend mt-10 mt-md-0" no-gutters>
          <v-col cols="6" sm="3" class="py-0">
            <div class="timeslot-legend">
              <div class="marker empty"></div>
              <span class="text-body-2">{{
                $t("timeslots.availability.empty")
              }}</span>
            </div>
          </v-col>
          <v-col cols="6" sm="3" class="py-0">
            <div class="timeslot-legend">
              <div class="marker intermediate"></div>
              <span class="text-body-2">{{
                $t("timeslots.availability.intermediate")
              }}</span>
            </div>
          </v-col>
          <v-col cols="6" sm="3" class="py-0">
            <div class="timeslot-legend">
              <div class="marker full"></div>
              <span class="text-body-2">{{
                $t("timeslots.availability.full")
              }}</span>
            </div>
          </v-col>
          <v-col cols="6" sm="3" class="py-0">
            <div class="timeslot-legend">
              <div class="marker selected"></div>
              <span class="text-body-2">{{
                $t("timeslots.availability.selected")
              }}</span>
            </div>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </div>
</template>
<style lang="scss">
.timeslot-selector {
  color: var(--v-default-base);
  height: 100%;
  .category-title {
    h1 {
      text-align: center;
      font-weight: 700 !important;
      font-size: 26px;
    }
  }
  .range-buttons {
    .v-btn {
      border: 1px solid $gray-border-color !important;
    }
    .active-border {
      border: 1px solid var(--v-primary-lighten1) !important;
    }
  }

  .selector-warning {
    margin: 0;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 14px;
    }
    &.selector-warning-under-lead-time {
      color: darken($lead-time-color, 30%);
    }
    &.selector-warning-under-lock-day {
      color: darken($day-lock-color, 30%);
    }
    &.selector-warning-under-lock-cutoff {
      color: darken($lock-cutoff-color, 30%);
    }
  }
  .v-tabs {
    flex-grow: unset;
  }
  .v-slide-group__wrapper {
    background-color: white;
  }
  .v-divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .title {
    font-size: 25px;
  }
  .subtitle {
    font-size: 16px;
  }
  .timeslot-container {
    flex-wrap: wrap;
    justify-content: center;
    min-height: 180px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-height: 200px;
    }
  }
  .legend {
    flex-grow: unset;
    margin-top: 10px !important;
  }
  .timeslot-card {
    overflow: hidden;
    justify-content: center;
  }
  .timeslot-button {
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    margin: 3px;
    font-size: 15px;
    font-weight: normal;
    pointer-events: auto;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 7px;
    position: relative;
    color: $text-color;

    .timeslot-time {
      font-size: 16px;
    }
  }
  .timeslot-legend {
    margin: 3px;
    display: flex;
    align-items: center;
    .marker {
      width: 32px;
      height: 32px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      border-radius: 4px;
    }
    span {
      vertical-align: middle;
    }
  }

  .empty {
    color: var(--v-default-darken2);
    background-color: var(--v-success-base);
    border: 1px solid #e9e9e9;
  }
  .intermediate {
    color: var(--v-default-darken2);
    background-color: var(--v-secondary-base);
    &.selected {
      border-color: $primary;
    }
  }
  .full {
    color: white;
    background: repeating-linear-gradient(
      135deg,
      #fcc,
      #fcc 4px,
      #fff 4px,
      #fff 8px
    );
    &.selected {
      border-color: $primary;
      background-color: $primary;
      color: $primary;
    }
    color: #182983;
  }
  .selected {
    color: #fff;
    background-color: $primary;
  }
  .v-tab {
    border: 1px solid var(--v-grey-lighten1);
    color: $primary;
    background-color: var(--v-grey-lighten3);
    padding: 0px !important;
    margin: 0px 5px 0px 5px !important;
    border-radius: 5px;
    min-width: 70px;
    min-height: 70px;

    text-transform: capitalize;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 100px;
      width: 100px !important;
    }
    .time-div {
      color: $text-color;
      .day-string {
        margin: 2px 0px;
        font-size: 16px;
        letter-spacing: 0.1px;
        @media #{map-get($display-breakpoints, 'xs-only')} {
          font-size: 12px;
        }
      }
      .day-number {
        font-size: 22px;
        letter-spacing: 0.17px;
      }
      .month {
        margin: 2px 0px;
        font-size: 14px;
        letter-spacing: 0.1px;
        @media #{map-get($display-breakpoints, 'xs-only')} {
          font-size: 12px;
        }
      }
    }
  }
  .v-tab.v-tab--active {
    border-radius: 5px;
    color: $white;
    background-color: $primary;
    min-width: 75px;
    .time-div {
      color: $white;
    }
  }

  .v-slide-group__content {
    height: 100%;
  }

  .address {
    line-height: 18px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .timeslot-button {
      padding: 7px 5px;
      .timeslot-time {
        font-size: 14px;
      }
      .timeslot-status {
        font-size: 10px;
      }
    }
  }
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import { mapState, mapGetters } from "vuex";
// import { forEachCartItem } from "~/service/ebsn";
// import toNumber from "lodash/toNumber";
// import isNumber from "lodash/isNumber";
// import split from "lodash/split";
import get from "lodash/get";

export default {
  // components: { CategoryBlock },
  name: "TimeslotSelector",
  data() {
    return {
      days: {},
      tab: 0,
      category: {},
      selectedTimeslotDay: {},
      disabled: false,
      rangeTab: 0,
      timeslotRange: {
        0: { id: 0, text: "timeslots.allTimeRanges" },
        1: { id: 1, icon: "$day", text: "timeslots.morning" },
        2: { id: 2, icon: "$night", text: "timeslots.afternoon" }
      }
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    }),
    editableAddress() {
      return !(this.$route.name == "Payment" || this.cart.cartStatusId == 7);
    },
    selectedServiceId() {
      return this.cart.shippingAddress.deliveryServiceId;
    },
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
    // ,
    // timeslotRestrictions() {
    //   var result = {};
    //   let maxLeadTime = 0;
    //   forEachCartItem(this.cart, item => {
    //     let leadTime = toNumber(item.product.productInfos.LEAD_TIME);
    //     if (isNumber(leadTime) && leadTime > maxLeadTime) {
    //       maxLeadTime = leadTime;
    //     }
    //     result.underLeadTimeItems = maxLeadTime > 0;

    //     if (item.product.productInfos.DAY_LOCK) {
    //       if (!result.dayLock) result.dayLock = [];
    //       let array = split(item.product.productInfos.DAY_LOCK, " ");
    //       if (item.product.productInfos.DAY_LOCK.indexOf(",") > -1) {
    //         array = split(item.product.productInfos.DAY_LOCK, ",");
    //       }
    //       for (let i = 0; i < array.length; i++) {
    //         if (array[i].indexOf("_") > -1) {
    //           // "day_deliveryDayPeriodId, es: lun_2"
    //           let daySplitted = array[i].split("_");
    //           result.dayLock.push({
    //             day: daySplitted[0],
    //             deliveryDayPeriodId: daySplitted[1]
    //           });
    //         } else {
    //           // "day, es: lun"
    //           result.dayLock.push({
    //             day: array[i],
    //             deliveryDayPeriodId: undefined
    //           });
    //         }
    //       }
    //     }

    //     if (item.product.productInfos.PRODUCT_LOCK_CUTOFF) {
    //       if (!result.lockCutoff) result.lockCutoff = [];
    //       let arrayLock = split(
    //         item.product.productInfos.PRODUCT_LOCK_CUTOFF,
    //         " "
    //       );
    //       if (item.product.productInfos.PRODUCT_LOCK_CUTOFF.indexOf(",") > -1) {
    //         arrayLock = split(
    //           item.product.productInfos.PRODUCT_LOCK_CUTOFF,
    //           ","
    //         );
    //       }
    //       // arrayLock[0] = "16:00-18:00";
    //       for (var i = 0; i < arrayLock.length; i++) {
    //         let hours = arrayLock[i].split("-");
    //         result.lockCutoff.push(hours);
    //       }
    //     }
    //   });
    //   result.leadTimeLimit = this.$dayjs().add(maxLeadTime, "hour");
    //   // result.dayLock = null;
    //   return result;
    // }
  },
  methods: {
    changeAddress() {
      this.$emit("submit", "setAddress");
    },
    async openAddressSelector() {
      if (await this.setAddress()) {
        await this.needTimeslot();
      }
    },
    getServiceParams(serviceId) {
      return this.$t("navbar.service." + serviceId);
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    checkDeliveryDayPeriodId(item) {
      if (this.rangeTab == 0) {
        return true;
      } else {
        return item.deliveryDayPeriodId == this.rangeTab;
      }
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(false);

      // if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
      //   _this.days.shift();
      // }

      for (var i = 0; i < _this.days.length; i++) {
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }
      return false;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("timeslot");
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
  }
};
</script>
